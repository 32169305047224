@import url("https://fonts.googleapis.com/css2?family=Martel+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");
html {
  --layoutWidthMax: 1080px;
  --columnPaddingNormal: 16px;
  font: 700 15px/1.6 "Martel Sans", sans-serif;
  overflow-x: hidden;
  --gutterWidth: 16px;
  --windowWidth: calc(100vw /*- var(--scrollbarWidth)*/);
  --layoutWidth: calc(var(--windowWidth) - var(--gutterWidth)*2);
  --columnCountMax: 1;
}

body {
  padding: 0;
  margin: 0;
}

html *, html :after, html :before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

/* header */
.HoverArrow {
  position: relative;
  top: 1px;
  margin-left: 5px;
  stroke-width: 2px;
  fill: none;
  stroke: currentColor;
}
.HoverArrow .HoverArrow__linePath {
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.HoverArrow .HoverArrow__tipPath {
  transition: transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.header {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  perspective: 2000px;
  z-index: 100;
  background-color: var(--backgroundColor);
}
.header .header-guidesContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header.theme--transparent .header-guidesContainer {
  display: none;
}
.header.theme--transparentactive.header-hasGuides::after {
  display: none;
}
.header .header-container {
  position: relative;
  max-width: calc(var(--layoutWidth) + var(--columnPaddingNormal) * 2);
  margin: 0 auto;
  padding: 0 16px;
}
.header .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 32px 16px 12px;
}
.header .nav-logo {
  display: block;
  margin: 0;
  padding-bottom: 1rem;
}
.header .nav-logo-link {
  display: block;
  outline: none;
  padding: 4px;
  margin: -4px;
  font-family: "Ubuntu", sans-serif;
  font-size: 24px;
}
.header .logo {
  height: 25px;
  width: auto;
}
.header .nav-header {
  display: block;
}
.header .nav-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.header .nav-item-link {
  font: 700 15px/1.6 "Martel Sans", sans-serif;
  padding: 10px 20px;
  color: var(--navColor);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
}
.header .nav-item-link[aria-haspopup] {
  cursor: default;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: color, opacity;
}
.header .nav-item-link.active {
  color: var(--navHoverColor);
  opacity: var(--navHoverOpacity);
}
.header .dropdown {
  position: absolute;
  top: 50px;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  opacity: 0;
  transition: all 250ms;
  pointer-events: none;
  z-index: 100;
}
.header .dropdown.active {
  transform: rotateX(0deg);
  opacity: 1;
  pointer-events: auto;
}
.header .dropdown .background {
  position: absolute;
  width: 380px;
  height: 365px;
  background: white;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transition: all 0;
}
.header .dropdown .arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  background: white;
  border-top-left-radius: 4px;
  transition: all 0;
  top: -6px;
}
.header .dropdown .links {
  position: relative;
  width: 300px;
  height: 365px;
  overflow: hidden;
  transition: all 0;
  z-index: 100;
}
.header .dropdown .links .link-group {
  position: absolute;
  transition: all 0ms;
}
.header .dropdown .links .link-group.left {
  transform: translateX(-150px);
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
.header .dropdown .links .link-group.right {
  transform: translateX(150px);
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
.header .dropdown .links .link-group.trans {
  transition: all 250ms;
}
.header .dropdown .SiteMenu {
  --siteMenuOffset: 0;
  --siteMenuRotateX: 0;
  display: inline-block;
  padding-top: 13px;
  height: calc(var(--siteMenuHeight) + 13px);
  transform: translateX(-50%) rotateX(var(--siteMenuRotateX));
  transform-origin: 50% -50px;
  transition: 250ms;
  transition-property: transform, width, height;
  will-change: transform, width, height;
  position: absolute;
  top: 0px;
  left: 50%;
}
.header .dropdown .SiteMenu[hidden] {
  --siteMenuRotateX: -15deg;
}
.header .dropdown .card {
  position: relative;
  height: 100%;
}
.header .dropdown .trans {
  transition: all 0.25s;
}
.header .SiteMenu_Section {
  --siteMenuSectionOffset: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateX(var(--siteMenuSectionOffset));
  transition: 250ms;
  transition-property: transform, opacity;
  will-change: transform, opacity;
  pointer-events: auto;
}
.header .SiteMenu_Section[hidden] {
  opacity: 0;
  pointer-events: none;
}
.header .SiteMenuSection {
  --siteMenuSpacing: 24px;
  position: relative;
}
.header .SiteMenuSection .SiteMenuSectionBody {
  padding: var(--siteMenuSpacing);
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteAboutNavLayout {
  display: inline-grid;
  grid: auto/repeat(2, 1fr);
  column-gap: 32px;
  --siteNavListMinWidth: 190px;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteAboutNavLayout .SiteMenuNavList + .SiteMenuNavList + .SiteMenuNavList {
  margin-top: 1rem;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteProductNavLayout {
  --columnCount: 2;
  display: inline-grid;
  grid: auto/repeat(var(--columnCount), 1fr);
  gap: 48px 32px;
  --siteNavListMinWidth: 254px;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteProductNavLayout .SiteMenuNavList + .SiteMenuNavList + .SiteMenuNavList {
  margin-top: 1rem;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteAccountNavLayout {
  display: inline-grid;
  grid: auto/repeat(1, 1fr);
  column-gap: 32px;
  --siteNavListMinWidth: 190px;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteAccountNavLayout .SiteMenuNavList + .SiteMenuNavList {
  margin-top: 1rem;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList {
  --siteNavIconSpacing: 10px;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListTitle {
  margin-bottom: 32px;
  font: 800 14px/1.538461538 "Martel Sans", sans-serif;
  text-transform: uppercase;
  color: #8898aa;
  pointer-events: none;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListTitle.small {
  margin-bottom: 8px;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList {
  min-width: var(--siteNavListMinWidth);
  margin: 0;
  padding: 0;
  list-style: none;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList.small {
  display: grid;
  grid: auto/repeat(var(--columnCount), 1fr);
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList.small .SiteMenuNavItem {
  margin-top: 0 !important;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList.small .about + .about {
  margin-top: 12px !important;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem {
  margin-left: 0;
  font: 500 14px/1.428571429 "Martel Sans", sans-serif;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteNavItem__arrow.HoverArrow {
  opacity: 0;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: opacity;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteNavItem__arrow.HoverArrow .HoverArrow__tipPath {
  transform: 0px;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .HoverArrow--sizeSmall {
  stroke-width: 1.5px;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink {
  display: inline-flex;
  color: #0a2540;
  outline: none;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemIconContainer {
  --iconSize: 32px;
  margin-top: 3px;
  flex: 0 0 auto;
  width: var(--iconSize);
  height: var(--iconSize);
  margin-right: var(--siteNavIconSpacing);
  transition: 250ms;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemIconContainer.icon-small {
  --iconSize: 16px;
  color: #88ADD2;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemIconContainer.small {
  --iconSize: 16px!important;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemIconContainer.small .productIcon {
  width: inherit;
  height: inherit;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemIconContainer .productIcon {
  width: inherit;
  height: inherit;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemLabelContainer {
  white-space: nowrap;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemLabelContainer .SiteMenuNavItemLabel {
  font-weight: 700;
  color: inherit;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink .SiteMenuNavItemLabelContainer .SiteMenuNavItemBody {
  color: inherit;
  opacity: 0.6;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: opacity;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink:hover .SiteNavItem__arrow.HoverArrow {
  opacity: 1;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink:hover .SiteMenuNavItemBody {
  opacity: 1;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink:hover .icon-small {
  color: #0A2540;
}
.header .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem + .SiteMenuNavItem {
  margin-top: 32px;
}
.header .nav-signin {
  display: initial;
  color: #fff;
}
.header .nav-signin .nav-signin-button {
  display: inline-block;
  padding: 6px 0 3px;
  padding-right: 12px;
  padding-left: 16px;
  background-color: hsla(0deg, 0%, 100%, 0.2);
  color: var(--knockoutColor);
  white-space: nowrap;
  transition-property: background-color, opacity;
  border-radius: 16.5px;
  font: 650 15px/24px "Martel Sans", sans-serif;
  font-stretch: 100%;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  outline: none;
  text-decoration: none;
  background-color: var(--buttonColor);
}
.header .nav-SiteMenu {
  display: var(--smallNavDisplay, initial);
}
.header .nav-SiteMenu .nav-SiteMenu-button {
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  background-color: var(--buttonColor);
  color: white;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: background-color, opacity;
}
.header .smallDropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--columnPaddingNormal);
  z-index: 1;
  transform: scale(0.95);
  transform-origin: 100% 0;
  opacity: 0;
  pointer-events: none;
  transition: 250ms;
  transition-property: transform, opacity;
}
.header .smallDropdown.active {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}
.header .card {
  position: relative;
  min-width: 100px;
  min-height: 72px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.header .card .SiteMenuClose {
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  outline: none;
  background: none;
}
.header .card .SiteMenuSectionFooter {
  margin: 4px;
  padding: 20px;
  border-radius: 4px;
  background-color: #f6f9fc;
}
.header .card .SiteMenuSectionFooter .SiteMenuSectionFooterContainer > .CtaButton:not(:first-of-type) {
  margin-left: 16px;
}
.header .card .SiteMenuSectionFooter .SiteMenuSectionFooterContainer {
  display: flex;
  justify-content: center;
}
.header .card .SiteMenuSectionFooter .SiteMenuSectionFooterContainer .SiteMenuSectionFooterButton {
  display: inline-block;
  padding: 6px 0 3px;
  padding-right: 12px;
  padding-left: 16px;
  background-color: #ADD8E6;
  color: white;
  white-space: nowrap;
  transition-property: background-color, opacity;
  border-radius: 16.5px;
  font: 600 15px/24px "Martel Sans", sans-serif;
  font-stretch: 100%;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  outline: none;
  text-decoration: none;
}
.header .card .SiteMenuSectionFooter .SiteMenuSectionFooterContainer .SiteMenuSectionFooterButton:hover {
  background-color: #0a2540;
}
.header.theme--transparent::after {
  display: none;
}
.header.header-hasGuides::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  margin: 0;
  border: none;
  background: linear-gradient(90deg, rgba(66, 71, 112, 0.09), rgba(66, 71, 112, 0.09) 50%, transparent 0, transparent);
  background-size: 8px 1px;
  content: "";
}

blockquote, figure, h1, h2, h3, h4, h5, h6, p, pre {
  margin: 0;
}

@media (pointer: fine) {
  a:hover .HoverArrow .HoverArrow__linePath {
    opacity: 1;
  }
  a:hover .HoverArrow .HoverArrow__tipPath {
    transform: translateX(3px);
  }
  .header .nav-signin .nav-signin-button:hover {
    background-color: var(--buttonHoverColor);
    opacity: 1;
  }
  .header .nav-SiteMenu .nav-SiteMenu-button:hover {
    background-color: var(--buttonHoverColor);
    opacity: 1;
  }
}
@media (max-width: 899px) {
  .header .nav-list {
    display: none;
  }
  .header .nav-signin {
    display: none;
  }
  .header .dropdown {
    display: none;
  }
  .header .card .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListTitle {
    margin-bottom: 18px !important;
  }
  .header .card .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList {
    margin: -4px;
  }
  .header .card .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList .SiteMenuNavItem .SiteMenuNavItemLink {
    padding: 6px;
  }
  .header .card .SiteMenuSection + .SiteMenuSection:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #DDD, #DDD 50%, transparent 0, transparent);
    background-size: 8px 1px;
    content: "";
  }
}
@media (max-width: 499px) {
  .nav-logo-link {
    font-size: 16px !important;
  }
}
@media (min-width: 600px) {
  html {
    --columnCountMax: 2;
  }
  .header .smallDropdown .card .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList {
    --columnCount: 3;
  }
}
@media (min-width: 900px) {
  html {
    --columnCountMax: 4;
  }
  .header {
    --smallNavDisplay: none;
  }
  .header .nav-container {
    padding: 12px 16px;
  }
  .header .smallDropdown {
    display: none;
  }
  .SiteMenuSection {
    --siteMenuSpacing: 32px!important;
    display: inline-block;
  }
}
@media (min-width: 1112px) {
  html {
    --layoutWidth: var(--layoutWidthMax);
    --gutterWidth: calc(var(--windowWidth)/2 - var(--layoutWidth)/2);
  }
}
@media (min-width: 400px) {
  .header .smallDropdown .card .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList {
    --columnCount: 2;
  }
}
@media (min-width: 750px) {
  .header .smallDropdown .card .SiteMenuSection .SiteMenuSectionBody .SiteMenuNavList .SiteMenuNavListList {
    --columnCount: 4;
  }
}
.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

.guides {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 16px;
  pointer-events: none;
}

.guidesContainer {
  display: grid;
  grid: 1fr/repeat(var(--columnCountMax), 1fr);
  position: relative;
  max-width: var(--layoutWidth);
  height: 100%;
  margin: 0 auto;
}

.guides-guide {
  width: 1px;
  background: linear-gradient(180deg, var(--guideDashedColor), var(--guideDashedColor) 50%, transparent 0, transparent);
  background-size: 1px 8px;
}

.guides-guide:first-of-type,
.guides-guide:last-of-type {
  background: var(--guideSolidColor);
  background-size: 1px 8px;
}

.guides-guide:last-of-type {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.theme--transparent {
  --backgroundColor: none;
  --linkColor: #fff;
  --linkHoverColor: var(--linkColor);
  --linkHoverOpacity: 0.6;
  --buttonColor: hsla(0,0%,100%,0.2);
  --buttonHoverColor: hsla(0,0%,100%,0.4);
  --accentColor: #fff;
  --knockoutColor: #fff;
  --textColor: #fff;
  --guideSolidColor: rgba(66,71,112,0.06);
  --guideDashedColor: rgba(66,71,112,0.09);
  --titleColor: #fff;
  --maskFadeColor: rgba(0,0,0,0.4);
  --navColor: #fff;
  --navHoverColor: #fff;
  --navHoverOpacity: 0.6;
  --stripeBackground: #fff;
}

.theme--white {
  --accentColor: #0a2540;
  --linkHoverOpacity: 0.6;
  --buttonHoverOpacity: 0.6;
  --backgroundColor: #fff;
  --linkColor: var(--accentColor);
  --linkHoverColor: #0a2540;
  --buttonColor: var(--accentColor);
  --buttonHoverColor: #0a2540;
  --buttonDisabledColor: #cfd7df;
  --buttonDisabledOpacity: 0.7;
  --knockoutColor: #fff;
  --knockoutDisabledColor: #8898aa;
  --guideSolidColor: rgba(66,71,112,0.06);
  --guideDashedColor: rgba(66,71,112,0.09);
  --titleColor: #0a2540;
  --textColor: #425466;
  --inputBackground: #f1f4f7;
  --inputPlaceholderColor: #acb9c5;
  --inputTextColor: #0a2540;
  --inputErrorAccentColor: #ff5191;
  --annotationColor: #8c9eb1;
  --maskFadeColor: rgba(0,0,0,0.4);
  --navColor: #0a2540;
  --navHoverColor: #0a2540;
  --navHoverOpacity: 0.6;
  --footerColor: #0a2540;
  --cardBorderColor: #cbd6e0;
  --cardBackground: #fff;
  --subcardBackground: #f6f9fc;
  --tableIconColor: #8c9eb1;
  --stripeAccentWhite: #fff;
  --stripeAccentLight: #e3e7ec;
  --stripeAccentDark: #0a2540;
  --bulletColor: #cfd7df;
  --footnoteTextColor: #4d5b78;
  --disclaimerTextColor: #707f98;
  --inlineCodeTextColor: #2c3a57;
  --inlineCodeBackground: #e6ecf2;
}

.theme--white.accent--Slate {
  --accentColor: #0a2540;
  --linkHoverOpacity: 0.6;
  --buttonHoverOpacity: 0.6;
}

.theme--white.accent--Blue {
  --accentColor: #b1ec90;
}

.theme--light {
  --accentColor: #b1ec90;
  --backgroundColor: #f6f9fc;
  --linkColor: var(--accentColor);
  --linkHoverColor: #0a2540;
  --buttonColor: var(--accentColor);
  --buttonHoverColor: #0a2540;
  --buttonDisabledColor: #cfd7df;
  --buttonDisabledOpacity: 0.7;
  --knockoutColor: #fff;
  --knockoutDisabledColor: #8898aa;
  --guideSolidColor: rgba(66,71,112,0.06);
  --guideDashedColor: rgba(66,71,112,0.09);
  --titleColor: #0a2540;
  --textColor: #425466;
  --inputBackground: #e6ebf1;
  --inputPlaceholderColor: #8898aa;
  --inputTextColor: #0a2540;
  --inputErrorAccentColor: #ff5191;
  --annotationColor: #8c9eb1;
  --maskFadeColor: rgba(0,0,0,0.4);
  --navColor: #0a2540;
  --navHoverColor: #0a2540;
  --navHoverOpacity: 0.6;
  --footerColor: #0a2540;
  --cardBorderColor: #cbd6e0;
  --cardBackground: #fff;
  --subcardBackground: #f6f9fc;
  --tableIconColor: #8c9eb1;
  --bulletColor: #cfd7df;
  --footnoteTextColor: #4d5b78;
  --disclaimerTextColor: #707f98;
  --inlineCodeTextColor: #2c3a57;
  --inlineCodeBackground: #dce6ee;
}

.theme--light.accent--Blue {
  --accentColor: #b1ec90;
}

.theme--light.accent--Pink {
  --accentColor: #FFD1DC;
}

.theme--light.accent--Slate {
  --accentColor: #0a2540;
  --linkHoverOpacity: 0.6;
  --buttonHoverOpacity: 0.6;
}

.theme--dark {
  --backgroundColor: #213018;
  --linkColor: var(--accentColor);
  --linkHoverColor: #fff;
  --linkHoverOpacity: 1;
  --buttonColor: var(--accentColor);
  --buttonHoverColor: #fff;
  --buttonDisabledColor: #6b7c93;
  --buttonHoverOpacity: 1;
  --buttonDisabledOpacity: 0.7;
  --knockoutColor: #0a2540;
  --knockoutDisabledColor: #e6ebf1;
  --guideSolidColor: rgba(66,71,112,0.3);
  --guideDashedColor: rgba(66,71,112,0.3);
  --titleColor: #fff;
  --textColor: #adbdcc;
  --inputBackground: #0c2e4e;
  --inputBackgroundAlt: #274869;
  --inputPlaceholderColor: #b6c2cd;
  --inputTextColor: #fff;
  --inputErrorAccentColor: #ff5191;
  --annotationColor: #8c9eb1;
  --maskFadeColor: rgba(0,0,0,0.4);
  --navColor: #fff;
  --navHoverColor: #fff;
  --navHoverOpacity: 0.6;
  --footerColor: #fff;
  --cardBorderColor: #0f395e;
  --cardBackground: #0c2e4e;
  --subcardBackground: #1f4468;
  --tableIconColor: #8c9eb1;
  --stripeAccentWhite: #fff;
  --stripeAccentLight: #fff;
  --stripeAccentDark: #0c2e4e;
  --bulletColor: #6b7c93;
  --footnoteTextColor: #adbdcc;
  --disclaimerTextColor: #707f98;
  --inlineCodeTextColor: #fff;
  --inlineCodeBackground: #1c4161;
}

.theme--dark.accent--Pink {
  --accentColor: #FFD1DC;
}

.theme--dark.accent--Blue {
  --accentColor: #b1ec90;
}

.HomepageHero.section {
  --sectionPaddingTopMax: 116;
}

.section {
  --sectionAnglePaddingTopBase: calc(var(--sectionAnglePaddingBaseMin)*1px + (var(--sectionAnglePaddingTopBaseMax) - var(--sectionAnglePaddingBaseMin))*(var(--windowWidth)/737 - 0.50882px));
  --sectionAnglePaddingBottomBase: calc(var(--sectionAnglePaddingBaseMin)*1px + (var(--sectionAnglePaddingBottomBaseMax) - var(--sectionAnglePaddingBaseMin))*(var(--windowWidth)/737 - 0.50882px));
  --sectionAnglePaddingTop: calc(var(--sectionAngleHeight) + var(--sectionAnglePaddingTopBase) - var(--sectionAngleSin)*var(--gutterWidth));
  --sectionAnglePaddingBottom: calc(var(--sectionAngleHeight) + var(--sectionAnglePaddingBottomBase) - var(--sectionAngleSin)*var(--gutterWidth));
  --sectionPaddingTop: calc(var(--sectionPaddingMin)*1px + (var(--sectionPaddingTopMax) - var(--sectionPaddingMin))*(var(--windowWidth)/737 - 0.50882px));
  --sectionPaddingBottom: calc(var(--sectionPaddingMin)*1px + (var(--sectionPaddingBottomMax) - var(--sectionPaddingMin))*(var(--windowWidth)/737 - 0.50882px));
  --sectionAngleSin: var(--angleNormalSin);
  --sectionAngle: 0;
  --sectionPaddingNormalMax: 128;
  --sectionPaddingSmallMax: 110;
  --sectionPaddingXSmallMax: 72;
  --sectionPaddingMin: 72;
  --sectionPaddingMax: var(--sectionPaddingNormalMax);
  --sectionPaddingTopMax: var(--sectionPaddingMax);
  --sectionPaddingBottomMax: var(--sectionPaddingMax);
  --sectionMarginBottom: 0;
  --sectionAngleHeight: calc(var(--windowWidth)*var(--sectionAngleSin));
  --sectionAnglePaddingBaseMin: 100;
  --sectionAnglePaddingBaseMax: var(--sectionPaddingMax);
  --sectionAnglePaddingTopBaseMax: var(--sectionAnglePaddingBaseMax);
  --sectionAnglePaddingBottomBaseMax: var(--sectionAnglePaddingBaseMax);
  --sectionAngleMaxHeight: none;
  --sectionOverflow: hidden;
  --sectionTransformOrigin: 100% 0;
  --sectionBackgroundOverflow: visible;
  position: relative;
  z-index: 1;
  margin-bottom: var(--sectionMarginBottom);
  color: var(--textColor);
  scroll-margin-top: calc(var(--fixedNavHeight) + var(--fixedNavSpacing) - var(--sectionPaddingTop));
}

.section_masked {
  overflow: hidden;
}

.section_backgroundMask {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.section_background {
  position: relative;
  height: 100%;
  max-height: var(--sectionAngleMaxHeight);
  width: 100%;
  top: 0;
  left: 0;
  transform-origin: var(--sectionTransformOrigin);
  transform: var(--sectionAngle);
  background: var(--backgroundColor);
  overflow: hidden;
}

.sectionContainer {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.sectionLayoutContainer {
  width: 100%;
  max-width: var(--layoutWidth);
  margin: 0 16px;
}

.sectionLayout {
  padding: var(--sectionPaddingTop) 0 var(--sectionPaddingBottom);
}

.ColumnLayout {
  --columnRowGap: 32px;
  display: grid;
  row-gap: var(--columnRowGap);
  align-items: flex-start;
}

.Copy {
  --titleFont: var(--titleWeight) var(--titleFontSize)/var(--titleLineHeight) "Martel Sans", sans-serif;
  --captionFont: 700 var(--captionFontSize, 18px)/var(--captionFontLineHeight, 1.555555556) "Martel Sans", sans-serif;
  --bodyFont: 500 var(--bodyFontSize, 18px)/var(--bodyFontLineHeight, 1.555555556) "Martel Sans", sans-serif;
  --paddingLeft: 16px;
  --paddingRight: 32px;
  --headerPaddingLeft: 16px;
  --headerPaddingRight: 32px;
  --headerMaxWidth: calc(calc(var(--layoutWidthMax)*.25)*3);
  --bodyPaddingLeft: var(--paddingLeft);
  --bodyPaddingRight: var(--paddingRight);
  --bodyMaxWidth: calc(calc(var(--layoutWidthMax)*.25)*3);
  --footerPaddingLeft: 16px;
  --footerPaddingRight: 16px;
  --footerRowGap: 24px;
  --ctaSpacing: 16px;
  --footerGap: "";
  scroll-margin-top: 108px;
}

.Copy, .Copy_header {
  display: grid;
  row-gap: var(--rowGap);
}

.Copy.variant-Superhero, .Copy.variant--Hero {
  --rowGap: 32px;
  --titleWeight: 700;
}

.Copy.variant--Hero {
  --titleFontSize: 48px;
  --titleLineHeight: 76.8px;
}

.HomepageHeroHeader {
  --titleFontMin: 50;
  --titleFontMax: 78;
  --viewportMin: 375;
  --viewportMax: 600;
  --titleFontSize: calc(var(--titleFontMin)*1px + (var(--titleFontMax) - var(--titleFontMin))*(var(--windowWidth) - var(--viewportMin)*1px)/(var(--viewportMax) - var(--viewportMin)));
  --titleLineHeight: 1.04;
  --titleLetterSpacing: -0.04em;
  --titleWeight: 700;
  --titleFont: var(--titleWeight) var(--titleFontSize)/var(--titleLineHeight) "Martel Sans", sans-serif;
  --headerMarginTop: 100px;
  --headerPadding: 0 var(--headerPaddingRight) 0 var(--headerPaddingLeft);
  --captionHeight: 25px;
  --captionTitleGap: 48px;
  --captionMarginTop: calc(var(--headerMarginTop) - var(--captionHeight) - var(--captionTitleGap));
  position: relative;
  min-width: 0;
  padding: var(--headerPadding);
}

.HomepageHeroHeaderCaption {
  margin-top: var(--captionMarginTop);
  user-select: none;
}

.HomepageHeroCaption_container {
  display: inline-flex;
  position: relative;
  padding: 4px 8px 3px 12px;
  border-radius: 12.5px;
  font: 500 13px/18px "Martel Sans", sans-serif;
  color: #fff;
}

.HomepageHeroCaption_container::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #121252;
  border-radius: inherit;
  opacity: 0.3;
  mix-blend-mode: color-burn;
  content: "";
}

.HomepageHeroCaption_content {
  z-index: 3;
}

.HomepageHeroCaption_title {
  font-weight: 700;
}

.HomepageHeroCaption_separator {
  margin: 0 4px 0 3px;
}

.link {
  font-weight: var(--linkWeight, 600);
  cursor: pointer;
  color: var(--linkColor);
  opacity: var(--linkOpacity, 1);
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: color, opacity;
  outline: none;
}

button.link {
  border: none;
  background: transparent;
  font: inherit;
  font-weight: var(--linkWeight, 600);
}

.HomepageHeroCaption_link.link {
  --linkColor: currentColor;
  --linkHoverColor: currentColor;
  --linkHoverOpacity: 0.6;
  --linkOpacity: 0.9;
}

.HomepageHeroGradient {
  --transformOriginX: 60px;
  position: absolute;
  bottom: 0;
  top: -200px;
  left: calc(var(--gutterWidth) * -1);
  width: var(--windowWidth);
  min-width: 1000px;
  height: 800px;
  overflow: hidden;
  border: none;
}

.background-granim {
  height: 650px;
  width: 100vw;
  transform: skewY(-12deg);
  transform-origin: 0;
}

.HomepageHeroHeader_title {
  margin-top: var(--headerMarginTop);
  position: relative;
  display: flex;
  align-items: flex-end;
  font: var(--titleFont);
  color: #bdc6d2;
}

.HomepageHeroHeader-hasCaption .HomepageHeroHeader_title {
  margin-top: var(--captionTitleGap);
}

.HomepageHeroHeader_title--overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 0 0 16px;
  z-index: 2;
  color: #3a3a3a;
  opacity: 0.3;
  user-select: none;
}

.HomepageHeroHeader_title--burn {
  mix-blend-mode: color-burn;
  opacity: 1;
}

.Copy_body {
  padding: 0 var(--bodyPaddingRight) 0 var(--bodyPaddingLeft);
  font: var(--bodyFont);
  color: var(--textColor);
  max-width: var(--bodyMaxWidth);
  z-index: 2;
}

.Copy_footer {
  display: grid;
  row-gap: var(--footerRowGap);
  margin-top: calc(var(--footerGap, var(--rowGap)) - var(--rowGap));
  padding: 0 var(--footerPaddingRight) 0 var(--footerPaddingLeft);
}

.Copy_footerContainer {
  display: flex;
  align-items: center;
}

.CtaButton {
  display: inline-block;
  padding: 6px 0 3px;
  border-radius: 16.5px;
  font: 600 15px/24px "Martel Sans", sans-serif;
  color: var(--buttonColor);
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  outline: none;
  user-select: none;
}

a {
  user-select: none;
}

.HomepageHero_cta {
  will-change: opacity;
}

.CtaButton.variant--Button {
  padding-left: 16px;
  padding-right: 16px;
  background-color: var(--buttonColor);
  color: var(--knockoutColor);
  white-space: nowrap;
  transition-property: background-color, opacity;
}

.CtaButton.variant--Button.CtaButton--arrow {
  padding-right: 12px;
}

.CtaButton.variant--Link {
  transition-property: color, opacity;
}

.Copy_footerContainer > .CtaButton:first-of-type,
.Copy_footerContainer > .CtaButton:last-of-type {
  margin-bottom: 16px;
}

.Copy_footerContainer > .CtaButton:not(:first-of-type) {
  margin-left: 16px;
}

.HomepageHeroGraphic {
  position: relative;
  z-index: 2;
}

.DashboardGraphic {
  --gutter: 24px;
  display: grid;
  grid: auto/152px 1fr;
  gap: var(--gutter);
  width: 929px;
  padding: var(--gutter);
  border-radius: 8px;
  background: linear-gradient(hsla(0deg, 0%, 100%, 0.4), hsla(0deg, 0%, 100%, 0.3) 25%, rgba(246, 249, 252, 0.3) 50%, #f6f9fc 60%);
  box-shadow: inset 0 1px 1px 0 hsla(0deg, 0%, 100%, 0.1), 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: #425466;
  user-select: none;
}

.HomepageHeroGraphic_dashboard {
  position: absolute;
  top: 0;
  left: 230px;
}

.DashboardGraphic_sidebar {
  display: grid;
  grid: max-content/auto-flow max-content;
  gap: 8px;
  align-items: center;
  font-weight: 800;
  color: #fff;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-size: 12px;
}

.DashboardGraphic_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DashboardGraphic_toolbar.DashboardGraphicToolbarTop {
  justify-content: flex-end;
  width: 100%;
}

.DashboardGraphic_box {
  margin-top: var(--gutter);
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px -1px rgba(6, 24, 44, 0.2);
}

.DashboardGraphic_toolbarHeading {
  padding: 16px;
}

.DashboardGraphic_toolbarTitle {
  font-weight: 700;
}

.DashboardGraphic_charts {
  display: grid;
  grid: auto/repeat(3, 1fr);
  border-top: 1px solid #e6ebf1;
}

.DashboardGraphic_column,
.DashboardGraphic_row {
  padding: 15px 16px 13px;
}

.DashboardGraphic_column--large {
  display: grid;
  grid-column: span 2;
}

.DashboardGraphic_column--split {
  display: grid;
  grid-auto-rows: 1fr;
  padding: 0;
}

.DashboardGraphic_column:not(:first-child) {
  border-left: 1px solid #e6ebf1;
}

.DashboardGraphic_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DashboardGraphic_row:not(:first-of-type) {
  border-top: 1px solid #e6ebf1;
}

.DashboardGraphic_primaryData {
  font-size: 16px;
  padding-top: 4px;
}

.DashboardGraphic_primaryData--accent {
  color: #ADD8E6;
}

.DashboardGraphic_mutedData {
  font-size: 10px;
  padding-top: 4px;
}

.DashboardGraphic_table {
  border-collapse: collapse;
  width: 100%;
}

.DashboardGraphic_tableData {
  border: 1px solid #e6ebf1;
  text-align: center;
  padding: 8px;
}

.DashboardGraphic_tableHeader {
  border: 1px solid #e6ebf1;
  text-align: center;
  padding: 8px;
}

.DashboardGraphic_tableAmountPos {
  background: #cbf4c9;
  border-radius: 4px;
  padding: 3px 0px 1px;
  width: 90%;
  margin: auto;
  vertical-align: center;
}

.DashboardGraphic_tableAmountNeg {
  background: #fde2dd;
  border-radius: 4px;
  padding: 2px 0px 0px;
  width: 90%;
  margin: auto;
  vertical-align: center;
}

.PhoneGraphic {
  width: 264px;
  height: 533px;
  padding: 8px;
  border-radius: 36px;
  background: #f6f9fc;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 3px 60px -30px rgba(0, 0, 0, 0.3), inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
  user-select: none;
  font-size: 16px;
}

.HomepageHeroGraphic_phone {
  position: relative;
  margin-top: 60px;
  left: 140px;
}

.PhoneGraphic_screen {
  position: relative;
  height: 100%;
  border-radius: 32px;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  background: #FFF;
}

.CheckoutPhoneGraphic {
  height: 100%;
  padding: 24px 16px;
  border-radius: 28px;
  background: #fff;
  font-size: 11px;
  font-weight: 500;
}

.CheckoutPhoneGraphic_description {
  margin-top: 15px;
  text-align: center;
  color: #0a2540;
  font-weight: 600;
}

.CheckoutPhoneGraphic_price {
  margin: 2px 0 16px;
  text-align: center;
}

.CheckoutPhoneGraphic_button {
  border-radius: 4px;
  padding: 5px 0;
  text-align: center;
  font-weight: 600;
  color: #fff;
  background: #0a2540;
  box-shadow: 0 2px 4px -1px rgba(50, 50, 93, 0.25), 0 1px 3px -1px rgba(0, 0, 0, 0.3);
}

.CheckoutPhoneGraphic__applePay {
  display: block;
  height: 14px;
  margin: 0 auto;
}

.CheckoutPhoneGraphic_separator {
  margin: 16px 0 12px;
  text-align: center;
  background: linear-gradient(#e6ebf1, #e6ebf1) no-repeat 0 50%/100% 1px;
}

.CheckoutPhoneGraphic_separatorTitle {
  display: inline;
  padding: 0 6px;
  background: #fff;
}

.CheckoutPhoneGraphic_input {
  min-height: 24px;
  line-height: 24px;
  margin: 6px 0 16px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.07), 0 2px 3px -1px rgba(50, 50, 93, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.12);
}

.CheckoutPhoneGraphic_input--card {
  display: grid;
  grid: auto/repeat(2, 1fr);
}

.CheckoutPhoneGraphic_placeholder {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  padding-left: 8px;
}

.CheckoutPhoneGraphic_placeholder--number {
  gap: 0 6px;
  grid-area: auto/span 2;
  align-items: center;
  padding-right: 6px;
  border-bottom: 1px solid #e6ebf1;
}

.CheckoutPhoneGraphic_placeholder--cvc {
  white-space: nowrap;
  padding-right: 4px;
  border-left: 1px solid #e6ebf1;
}

.CheckoutPhoneGraphic__cvcIcon {
  margin: 4px 0 0 4px;
}

.CheckoutPhoneGraphic_placeholder--country {
  align-items: center;
  border-bottom: 1px solid #e6ebf1;
  padding-right: 8px;
}

.RowLayout {
  --rowLayoutGapXSmall: 16px;
  --rowLayoutGapSmall: 24px;
  --rowLayoutGapNormal: 32px;
  --rowLayoutGapMedium: var(--rowLayoutGapNormal);
  --rowLayoutGapLarge: var(--rowLayoutGapNormal);
  --rowLayoutGapXLarge: var(--rowLayoutGapNormal);
  --rowLayoutGap: var(--rowLayoutGapLarge);
  display: grid;
  grid: auto/minmax(0, 1fr);
  row-gap: var(--rowLayoutGap);
  align-items: flex-start;
}

.Copy.variant--Section, .Copy.variant--Subsection {
  --paddingRight: var(--columnPaddingXLarge);
  --rowGap: 24px;
  --titleWeight: 700;
  --paragraphGap: 20px;
}

.Copy.variant--Section {
  --titleFontSize: 34px;
  --titleLineHeight: 1.2;
}

.Copy_header {
  position: relative;
  padding: 0 var(--headerPaddingRight) 0 var(--headerPaddingLeft);
  max-width: var(--headerMaxWidth);
}

.CopyCaption {
  font: var(--captionFont);
  color: var(--accentColor);
}

.CopyTitle {
  position: relative;
  font: var(--titleFont);
  color: var(--titleColor);
  font-size: var(--titleFontSize);
}

.CopyTitle:before {
  display: var(--titleAnchorDisplay, block);
  position: absolute;
  top: calc(0.5px + var(--titleLineHeight) * var(--titleFontSize) / 2 - var(--titleFontSize) / 2);
  left: calc(var(--headerPaddingLeft) * -1);
  width: 1px;
  height: var(--titleFontSize);
  background-color: var(--titleBorderColor, transparent);
  content: "";
}

.ReactModal__Overlay--after-open {
  z-index: 100;
}

.ReactModal__Content--after-open {
  padding: 0 !important;
}
.ReactModal__Content--after-open .section {
  width: 100%;
  height: 100%;
}
.ReactModal__Content--after-open .section .Copy_header {
  margin-top: 2rem;
}

html {
  --columnPaddingMedium: 16px;
  --columnPaddingLarge: 16px;
  --columnPaddingXLarge: 16px;
}

@media (pointer: fine) {
  .link:hover {
    color: var(--linkHoverColor, var(--linkColor));
    opacity: var(--linkHoverOpacity, 1);
  }
  .CtaButton.variant--Button:hover {
    background-color: var(--buttonHoverColor);
    opacity: var(--buttonHoverOpacity, 1);
    cursor: pointer;
  }
  .CtaButton.variant--Link:hover {
    color: var(--buttonHoverColor);
    opacity: var(--linkHoverOpacity, 1);
  }
}
@media (min-width: 1112px) {
  .section {
    --sectionAnglePaddingTopBase: calc(var(--sectionAnglePaddingTopBaseMax)*1px);
    --sectionAnglePaddingBottomBase: calc(var(--sectionAnglePaddingBottomBaseMax)*1px);
    --sectionPaddingTop: calc(var(--sectionPaddingTopMax)*1px);
    --sectionPaddingBottom: calc(var(--sectionPaddingBottomMax)*1px);
  }
  .HomepageHeroHeader {
    --titleFontSize: 94px;
  }
  .Copy.variant--Section {
    --titleFontSize: 38px;
    --titleLineHeight: 1.2;
  }
}
.HomepageInfraGraphic {
  position: relative;
}

.GraphicWallGrid {
  --gridContainerHeightXSmall: 374px;
  --gridContainerHeightSmall: 474px;
  --gridContainerHeightMedium: 654px;
  --gridContainerHeightLarge: 764px;
  --gridRowsXSmall: 107px 87px 125px 185px;
  --gridRowsSmall: 70px 157px 97px 333px;
  --gridRowsMedium: 86px 201px 126px 425px;
  --gridRowsLarge: 101px 235px 148px 500px;
  --gridColumnsXSmall: 112px 141px 58px 38px;
  --gridColumnsSmall: 253px 392px 201px;
  --gridColumnsMedium: 323px 501px 256px;
  --gridColumnsLarge: 380px 589px 301px;
  --gridYOffsetXSmall: -30px;
  --gridYOffsetSmall: -30px;
  --gridYOffsetMedium: -80px;
  --gridYOffsetLarge: -110px;
  --gridGapXSmall: 16px 16px;
  --gridGapSmall: 25px 25px;
  --gridGapMedium: 35px 35px;
  --gridGapLarge: 40px 40px;
  position: relative;
  height: var(--gridContainerHeightXSmall);
  pointer-events: none;
  user-select: none;
}

.GraphicWallGrid_layout {
  display: grid;
  gap: var(--gridGapXSmall);
  grid-template-columns: var(--gridColumnsXSmall);
  grid-template-rows: var(--gridRowsXSmall);
  position: absolute;
  top: var(--gridYOffsetXSmall);
  pointer-events: none;
}

.GraphicWallGrid_section {
  display: flex;
  flex-direction: column;
}

.GraphicWallGrid_section--topLeft {
  grid-area: 2/2;
}

.DomGraphic {
  --maxWidth: 100%;
  --willChange: initial;
  position: relative;
  width: 100%;
  max-width: var(--maxWidth);
}

.DomGraphic_heightContainer {
  height: 0;
  width: 100%;
  max-width: var(--maxWidth);
  padding-bottom: var(--aspectRatio);
}

.DomGraphic_scaleContainer {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  will-change: var(--willChange);
}

.CardGraphic {
  position: relative;
  width: 380px;
  height: 235px;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
}

.CardGraphic_container {
  width: 100%;
  height: 100%;
  overflow: inherit;
  border-radius: inherit;
}

.CardGraphic_designs, .CardGraphic_designs > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.AtomTechCardDesign {
  background-color: #ADD8E6;
}

.AtomTechCardDesign_logo {
  position: absolute;
  top: 23px;
  left: 29px;
  font-size: 16px;
  color: #fff;
}

.AtomTechCardDesign_graphic {
  position: absolute;
  width: 80px !important;
  height: 80px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.CardGraphic_chipContainer {
  width: 53px;
  height: 47px;
  top: 80px;
  position: absolute;
  left: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CardGraphic_chipContainer::before {
  height: 100%;
  box-shadow: inset 0 1px 0 0 hsla(0deg, 0%, 100%, 0.65);
  top: 1px;
  left: 1px;
  right: 1px;
  z-index: 3;
  border-radius: 6px;
}

.CardGraphic_chipContainer::after {
  background: rgba(0, 0, 0, 0.3);
  width: 53px;
  height: 47px;
  border-radius: 7px;
  box-shadow: 0 1px 0 0 hsla(0deg, 0%, 100%, 0.15);
  top: 0;
  left: 0;
}

.CardGraphic_chipContainer::before,
.CardGraphic_chipContainer::after {
  position: absolute;
  content: "";
}

.CardGraphic__chip {
  z-index: 1;
  position: relative;
}

.CardGraphic_name {
  position: absolute;
  left: 29px;
  bottom: 20px;
  color: #fff;
}

.CardGraphic__visa {
  width: 84px;
  position: absolute;
  bottom: 18px;
  right: 18px;
}

.GraphicWallGrid_section--topCenter {
  grid-area: 3/2/3/4;
}

.WebsiteGraphic {
  width: 588px;
  height: 422px;
  overflow: hidden;
  border-radius: 8px;
  background: #f6f9fc;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  font-size: 13px;
  font-weight: 500;
  color: #425466;
}

.WebsiteGraphic_background {
  width: inherit;
  height: inherit;
  height: 67%;
  overflow: hidden;
  z-index: 2;
}

.WebsiteGraphic_container {
  width: inherit;
  height: inherit;
}

.WebsiteGraphic_icon {
  width: 24px !important;
  height: 24px !important;
}

.DomGraphic_scaleContainer {
  transform: scale(var(--scale));
}

.HomepageInfraGraphic_website {
  --maxWidth: 588px;
  --aspectRatio: 71.7687%;
  --scale: 1;
}
.HomepageInfraGraphic_website .header .nav-signin {
  display: initial !important;
}
.HomepageInfraGraphic_website .header .nav-list {
  display: flex !important;
}
.HomepageInfraGraphic_website .header .nav-container {
  padding: 12px 16px;
}

.HomepageInfraGraphic_card {
  --maxWidth: 380px;
  --aspectRatio: 61.8421%;
  --scale: 1;
}

.HomepageInfraGraphic_reader {
  --maxWidth: 301px;
  --aspectRatio: 187.375%;
  --scale: 1;
}

.GraphicWallGrid_section--topRight {
  grid-area: 1/3/2/5;
}

.TerminalGraphic {
  width: 301px;
  height: 564px;
  overflow: hidden;
  border-radius: 40px;
  background: #30455D;
  box-shadow: 0 20px 30px -10px;
}

.TerminalGraphic_edge {
  height: 552px;
  padding: 5px;
  border-radius: 40px;
  background: #18324b;
  box-shadow: 0 2px 6px 1px #0a2540, inset 0 -5px 2px 1px #0a2540, inset 0 2px 3px 1px #adbccb;
}

.TerminalGraphic__deviceArrow {
  display: block;
  width: 15px;
  margin: 1px auto 0;
}

.TerminalGraphic_front {
  height: 532px;
  padding: 15px;
  background: linear-gradient(#4b5f74, #273f57);
  box-shadow: 0 1px 1px 0 rgba(6, 24, 44, 0.2), inset 0 1px 1px 0 hsla(0deg, 0%, 100%, 0.3);
  border-radius: 35px;
}

.TerminalGraphic_screenEdge {
  height: 262px;
  padding: 20px;
  background: linear-gradient(#1c354e, #0b2641);
  border-radius: 20px;
}

.TerminalGraphic_screenContainer {
  position: relative;
  height: 100%;
}

.TerminalGraphic_screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 2px;
  background: linear-gradient(#2d445b, #132d47);
}

.TerminalGraphic_insertCardScreen-check {
  --height: 29px;
  position: absolute;
  top: 50%;
  left: calc(50% - var(--height) / 2);
  opacity: 1;
}

.TerminalGraphic_insertCardScreen-card {
  border: 2px solid #fff;
  width: 73px;
  height: 112px;
  position: absolute;
  border-radius: 6px;
  left: calc(50% - 36.5px);
  top: 42px;
  background: linear-gradient(#4b5f72, #40556a);
}

.TerminalGraphic_insertCardScreen-chip {
  background: #fff;
  position: absolute;
  top: 11px;
  left: 39px;
  width: 18px;
  height: 21px;
  border-radius: 2px;
}

.TerminalGraphic_insertCardScreen-fadeOut {
  position: absolute;
  top: -1px;
  left: calc(50% - 54px);
  width: 107px;
  height: 39px;
  padding: 0 2px 2px;
  border-radius: 0 0 6px 6px;
  background: linear-gradient(hsla(0deg, 0%, 100%, 0) 25%, hsla(0deg, 0%, 100%, 0.15) 85%, hsla(0deg, 0%, 100%, 0.5));
}

.TerminalGraphic_insertCardScreen-cutOff {
  height: 100%;
  border-radius: 0 0 4px 4px;
  background: linear-gradient(#2c435a, #294158);
}

@keyframes arrow {
  0%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-5px);
    opacity: 0.35;
  }
}
.TerminalGraphic_insertCardScreen-screenArrow {
  animation: arrow 2.5s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0s infinite;
  width: 16px;
  margin: 23px 0 0 57px;
  will-change: top, opacity;
}

.TerminalGraphic_insertCardScreen-logos {
  position: absolute;
  bottom: 22px;
  left: calc(50% - 83px);
  display: block;
  width: 166px;
}

.TerminalGraphic_keypad {
  display: grid;
  grid: auto-flow 34px/repeat(3, 1fr);
  gap: 12px;
  margin: 13px 9px 0;
}

.TerminalGraphic_button {
  border-radius: 8px;
  background: linear-gradient(rgba(10, 37, 64, 0), rgba(10, 37, 64, 0.25));
  box-shadow: 0 0 0 2px rgba(6, 24, 44, 0.4), 0 4px 6px -1px rgba(6, 24, 44, 0.65), inset 0 1px 0 hsla(0deg, 0%, 100%, 0.08);
}

.TerminalGraphic_button-light {
  background: linear-gradient(hsla(0deg, 0%, 100%, 0.18), hsla(0deg, 0%, 100%, 0.08));
}

.TerminalGraphic_button-red {
  background: linear-gradient(#e36456, #d14a3f);
}

.TerminalGraphic_button-green {
  background: linear-gradient(#42bb90, #2f9d71);
}

.GraphicWallGrid_section--bottomLeft {
  grid-area: 2/1;
}

.HomepageInfraGraphic_phone {
  --max-width: 301px;
  --aspectRatio: 204.319%;
  --scale: 1;
}

.PhoneGraphic_call {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  border-radius: 36px;
}

.PhoneGraphic_info {
  margin-top: 64px;
  font-weight: 600;
}

.PhoneGraphic_name {
  font-size: 20px;
}

.PhoneGraphic_duration {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.PhoneGraphic_buttonsLayout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.PhoneGraphic_button {
  display: grid;
  justify-content: center;
  align-content: center;
}

.PhoneGraphic_button-button {
  background: hsla(0deg, 0%, 100%, 0.1);
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneGraphic_button-buttonEnabled {
  background: #FFF;
  color: #000;
}

.PhoneGraphic_button-caption {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  padding-top: 4px;
}

.KeypadLayout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
}

.KeypadCircle {
  width: 8px;
  height: 8px;
  background: #FFF;
  border-radius: 4px;
}

.PhoneGraphic_endCall {
  margin-bottom: 16px;
}

.PhoneGraphic_button-buttonEnd {
  background: #FF0000;
}

.GraphicWallGrid_section--bottomRight {
  grid-area: 4/1/4/4;
}

.HomepageInfraGraphic_dashboard {
  --maxWidth: 929px;
  --aspectRatio: 55.113%;
  --scale: 1;
}

.HomeDashboardGraphic {
  --gutter: 24px;
  display: grid;
  grid: auto/152px 1fr;
  gap: var(--gutter);
  width: 929px;
  padding: var(--gutter);
  border-radius: 8px;
  background-image: linear-gradient(#f5f8fb, #f1f6fa 15%);
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  font-size: 11px;
  font-weight: 500;
  color: #425466;
}

.HomeDashboardGraphic_sidebar {
  --nav-spacing: 8px;
  --nav-icon-box: 17px;
  color: #425466;
}

.HomeDashboardGraphic_account {
  display: grid;
  grid: max-content/auto-flow max-content;
  gap: var(--nav-spacing);
  align-items: center;
  margin-bottom: var(--gutter);
  font-weight: 700;
  text-transform: uppercase;
}

.HomeDashboardGraphic_customerLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(6, 24, 44, 0.2);
}

.HomeDashboardGraphic_navItem {
  display: grid;
  grid: auto/var(--nav-icon-box) -webkit-max-content;
  grid: auto/var(--nav-icon-box) max-content;
  gap: var(--nav-spacing);
  place-items: center;
  margin-bottom: var(--nav-spacing);
  line-height: 14px;
}

.HomeDashboardGraphic_navItem:last-of-type {
  margin-top: var(--gutter);
}

.HomeDashboardGraphic_navSubItem {
  margin: 0 0 var(--nav-spacing) calc(var(--nav-icon-box) + var(--nav-spacing));
  line-height: 14px;
}

.HomeDashboardGraphic_selectedItem {
  color: #ADD8E6;
  font-weight: 650;
}

.HomeDashboardGraphic_toolbarTop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.HomeDashboardGraphic_box {
  --box-padding: 16px;
  margin-top: var(--gutter);
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 2px 4px -1px rgba(6, 24, 44, 0.2);
}

.HomeDashboardGraphic_toolbar {
  display: grid;
  grid: auto/1fr;
  grid-auto-flow: column;
  align-items: center;
}

.HomeDashboardGraphic_toolbarTitle {
  padding-left: var(--box-padding);
  margin: 13px 0 13px 0px;
  font-weight: 700;
}

.HomeDashboardGraphic_hardwareLayout {
  display: grid;
  grid: auto/auto 1fr;
}

.HomeDashboardGraphic_hardware, .HomeDashboardGraphic_hardwareDescription {
  border-top: 1px solid #e6ebf1;
  padding: var(--box-padding);
}

.HomeDashboardGraphic_hardwareTitle {
  text-align: center;
}

.HomeDashboardGraphic_hardwareDescription {
  border-left: 1px solid #e6ebf1;
}

.HomeDashboardGraphic_hardwareDescriptionTitleToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.HomeDashboardGraphic_hardwareDescriptionTitleToolbarButton {
  padding: 3px 8px;
  box-shadow: 0 0 1px 0 rgba(6, 24, 44, 0.18), 0 1px 2px 0 rgba(6, 24, 44, 0.2);
  border-radius: 4px;
  line-height: 14px;
}

.HomeDashboardGraphic_hardwareDescriptionTitle {
  font-weight: 700;
}

.Copy.variant--Detail {
  --titleBorderColor: var(--accentColor);
  --titleFontSize: 15px;
  --titleLineHeight: 1.6;
  --titleWeight: var(--fontWeightSemibold);
  --titleLetterSpacing: 0.2px;
  --paragraphGap: 8px;
  --bodyFontSize: 15px;
  --bodyLineHeight: 1.6;
}

.CopyIcon {
  min-width: 40px;
  min-height: 40px;
  color: var(--accentColor);
}

.CopyIcon-icon {
  min-width: inherit;
  min-height: inherit;
  width: 100px;
}

.slider {
  width: 100%;
}

table, th, td {
  border: 1px solid darkgray;
  border-collapse: collapse;
  padding: 8px;
}

@media (max-width: 1111px) {
  .guides {
    max-width: var(--windowWidth);
  }
}
@media (max-width: 599px) {
  .guides-guide:nth-of-type(3n) {
    display: none;
  }
  .HomepageHeroGraphic {
    display: none;
  }
}
@media (min-width: 600px) {
  html {
    --columnPaddingMedium: 32px;
    --columnPaddingLarge: 64px;
    --columnPaddingXLarge: 64px;
  }
  .ColumnLayout[data-columns="2,2"], .ColumnLayout[data-columns="2,1,1"], .ColumnLayout[data-columns="2,1,0"], .ColumnLayout[data-columns="1,1,1,1"] {
    grid-template-columns: repeat(2, 1fr);
  }
  .HomepageHeroHeader {
    --headerPaddingRight: 0;
    --titleFontMin: 60;
    --titleFontMax: 94;
    --viewportMin: 600;
    --viewportMax: 1112;
  }
  .HomepageHeroHeaderCaption {
    min-width: calc(100% + 100px);
  }
  .HomepageHeroHeader_title {
    min-width: calc(100% + 100px);
    min-height: 200px;
  }
  .RowLayout {
    --rowLayoutGapMedium: 48px;
    --rowLayoutGapLarge: var(--rowLayoutGapMedium);
    --rowLayoutGapXLarge: var(--rowLayoutGapMedium);
  }
}
@media (min-width: 750px) {
  .HomepageHeroCaption_container {
    white-space: nowrap;
  }
}
@media (min-width: 900px) {
  html {
    --columnPaddingXLarge: 112px;
  }
  .RowLayout {
    --rowLayoutGapLarge: 64px;
    --rowLayoutGapXLarge: 96px;
  }
  .DomGraphic {
    max-width: var(--maxWidthDesktop, var(--maxWidth));
  }
  .ColumnLayout[data-columns="3,1"] {
    grid-template-columns: 3fr 1fr;
  }
  .ColumnLayout[data-columns="1,1,1,1"] {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 397px) {
  .GraphicWallGrid {
    height: var(--gridContainerHeightSmall);
  }
  .GraphicWallGrid_section--topLeft {
    grid-area: 2/1;
  }
  .GraphicWallGrid_section--topCenter {
    grid-area: 2/2/span 2;
  }
  .GraphicWallGrid_section--topRight {
    grid-area: span 2/3;
  }
  .GraphicWallGrid_section--bottomLeft {
    grid-area: 3/1;
  }
  .GraphicWallGrid_section--bottomRight {
    grid-area: 4/2/4/4;
  }
  .GraphicWallGrid_layout {
    top: var(--gridYOffsetSmall);
    gap: var(--gridGapSmall);
    grid-template-columns: var(--gridColumnsSmall);
    grid-template-rows: var(--gridRowsSmall);
  }
}
@media (min-height: 900px) {
  .GraphicWallGrid {
    height: var(--gridContainerHeightLarge);
  }
}
@media (min-width: 896px) and (min-height: 900px) {
  .GraphicWallGrid_layout {
    top: var(--gridYOffsetLarge);
    gap: var(--gridGapLarge);
    grid-template-columns: var(--gridColumnsLarge);
    grid-template-rows: var(--gridRowsLarge);
  }
}
@media (min-width: 896px) and (max-height: 899px) {
  .GraphicWallGrid_layout {
    top: var(--gridYOffsetMedium);
    gap: var(--gridGapMedium);
    grid-template-columns: var(--gridColumnsLarge);
    grid-template-rows: var(--gridRowsMedium);
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .DomGraphic {
    max-width: var(--maxWidthTablet, var(--maxWidth));
  }
}
@media (max-height: 900px) {
  .HomepageInfraGraphic_website {
    --scale: 0.852041;
  }
  .HomepageInfraGraphic_card {
    --scale: 0.85;
  }
  .HomepageInfraGraphic_reader {
    --scale: 0.850498;
  }
  .HomepageInfraGraphic_phone {
    --scale: 0.912116;
  }
  .HomepageInfraGraphic_dashboard {
    --scale: 0.85253;
  }
}
@media (max-width: 895px) {
  .HomepageInfraGraphic_website {
    --scale: 0.666666667;
  }
  .HomepageInfraGraphic_card {
    --scale: 0.666666667;
  }
  .HomepageInfraGraphic_reader {
    --scale: 0.667774;
  }
  .HomepageInfraGraphic_phone {
    --scale: 0.840532;
  }
  .HomepageInfraGraphic_dashboard {
    --scale: 0.665231;
  }
}
@media (max-width: 396px) {
  .HomepageInfraGraphic_website {
    --scale: 0.365646;
  }
  .HomepageInfraGraphic_card {
    --scale: 0.371053;
  }
  .HomepageInfraGraphic_reader {
    --scale: 0.372093;
  }
  .HomepageInfraGraphic_phone {
    --scale: 0.372093;
  }
  .HomepageInfraGraphic_dashboard {
    --scale: 0.369214;
  }
}
@media (max-width: 899px) {
  .guides-guide:nth-of-type(2n) {
    display: none;
  }
  .ColumnLayout[data-tablet-columns="1"] {
    grid-template-columns: 1fr;
  }
}
.SiteFooterSection_layout {
  display: grid;
  grid: auto/repeat(2, 1fr);
  row-gap: 20px;
}

.SiteFooterSection_column {
  display: grid;
  row-gap: 20px;
}

.SiteFooterSection_logo {
  height: 50px;
  width: 46.3906px;
  margin: 0 16px 6px;
  padding-left: 2px;
}

.SiteFooterSection_logoLink {
  width: inherit;
  height: inherit;
}

.SiteFooterSection_logo-logo {
  width: inherit;
  height: inherit;
  display: block;
}

.SiteFooterSection .List {
  --linkWeight: 500;
}

.List {
  --listSpacing: 2px;
  --columnCount: 1;
  --paddingLeft: 16px;
  --paddingRight: 16px;
  padding: 0 16px 0 16px;
  font: 500 15px "Martel Sans", sans-serif;
}

.List_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.List_item {
  position: relative;
  margin: var(--listSpacing) 0;
}

.SiteFooterSection_copyright {
  color: #364657;
}

.List_title.CopyTitle {
  --titleFontSize: 15px;
  --titleWeight: 650;
  --linkColor: currentColor;
  --linkWeight: 650;
  margin-bottom: 8px;
  color: #0a2540;
  font-weight: 650;
}

.CopyTitle--inline {
  --titlePaddingLeft: 0px;
  --titlePaddingRight: 0px;
}

.List--hasTitle .List_list {
  --linkColor: currentColor;
  --linkWeight: 600;
}

@media (min-width: 600px) {
  .SiteFooterSection_layout {
    grid: auto/repeat(4, 1fr);
  }
  .SiteFooterSection_column {
    grid: min-content/auto;
  }
  .SiteFooterSection_column--copyright {
    grid-template-rows: auto min-content;
  }
}
.ProductHeroSection.section {
  --sectionLayoutMarginBottom: 0;
  --sectionLayoutMarginTop: 0;
  --sectionPaddingMax: 72;
  --sectionPaddingTop: 0;
}

.ProductHeroSection_layout {
  position: relative;
  margin-bottom: var(--sectionLayoutMarginBottom);
  margin-top: var(--sectionLayoutMarginTop);
}

.ProductNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 16px 16px;
  color: var(--accentColor);
}

.ProductNav_header {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.ProductNav_icon {
  margin-right: 16px;
  width: 40px !important;
  height: 40px !important;
}

.HorizontalOverflowContainer {
  --trackHeight: auto;
  --maskSpacing: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(90deg, transparent, #000 var(--maskSpacing), #000 calc(100% - var(--maskSpacing)), transparent);
  mask-image: linear-gradient(90deg, transparent, #000 var(--maskSpacing), #000 calc(100% - var(--maskSpacing)), transparent);
}

.HorizontalOverflowContainer_track {
  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: var(--trackHeight);
  scrollbar-width: none;
}

.HorizontalOverflowContainer_track::-webkit-scrollbar {
  display: none;
}

.HorizontalOverflowContainer_items {
  display: flex;
  position: relative;
  padding: 0 16px;
}

.ProductNav_linkList {
  margin: 0;
  padding: 0;
  display: flex;
}

.ProductNavItem {
  display: inline-block;
  padding: 0 8px;
  list-style: none;
  font: 600 15px "Martel Sans", sans-serif;
  white-space: nowrap;
  --linkWeight: 700;
}

.ProductNavItem--isActive {
  --linkColor: var(--navColor);
  --linkHoverOpacity: 0.6;
}

.ProductNavItem:first-of-type {
  padding-left: 0;
}

.enquireForm {
  background-color: #2c4220;
  border: 1px solid #26371c;
  overflow: hidden;
  position: relative;
  min-width: 100px;
  min-height: 72px;
  border-radius: 8px;
}

.formContainer {
  padding: 16px;
  width: 100%;
}

.formList {
  padding: 0;
  margin: 0;
}

.formItem {
  list-style: none;
  display: flex;
  width: 100%;
}

.formItem + .formItem {
  margin-top: 12px;
}

.formLabelContainer {
  display: flex;
  flex-direction: column;
  margin: 0 0 4px;
}

.formLabel {
  color: #FFF;
  font-weight: 625;
  font-size: 15px;
  line-height: 24px;
}

.formInputContainer {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.formInput {
  background-color: #39542a;
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 5px 12px 7px;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.formInput::placeholder {
  color: #b6c2cd;
}

.formRadioFieldset {
  display: inline-grid;
  grid: auto/repeat(2, 1fr);
  gap: 4px 40px;
  padding: 4px 0;
  margin: 0;
  border: none;
}

.CheckboxField {
  position: relative;
  display: grid;
  grid: auto/min-content auto;
  align-items: baseline;
  gap: 8px;
  cursor: pointer;
}

.CheckboxField_hiddenInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.CheckboxField_checkbox {
  position: relative;
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  background: #39542a;
  border-radius: 4px;
  top: -1px;
}

.CheckboxField_checkbox::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--accentColor);
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: opacity;
  opacity: 0;
  content: "";
}

.CheckboxField:hover .CheckboxField_checkbox::before {
  opacity: 0.2;
}

.CheckboxField_hiddenInput:checked + .CheckboxField_checkbox::before {
  opacity: 1;
}

.CheckboxField__check {
  transform: scale(0.5);
  opacity: 0;
  transition: 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-property: opacity, transform;
}

.CheckboxField_hiddenInput:checked + .CheckboxField_checkbox .CheckboxField__check {
  transform: scale(1);
  opacity: 1;
}

.CheckboxField_label {
  user-select: none;
}

.formLabelDescription {
  font-size: 12px;
}

.formFooter {
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
}

button.CtaButton {
  border: none;
}

.RadioField {
  position: relative;
  display: grid;
  grid: auto/min-content auto;
  align-items: baseline;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
}

.RadioField_hiddenInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.RadioField_checkbox {
  position: relative;
  top: 3px;
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  background: #274869;
  border-radius: 50%;
}

.RadioField_checkbox::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--accentColor);
  transition: 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-property: opacity;
  opacity: 0;
  content: "";
}

.RadioField:hover .RadioField_checkbox::before {
  opacity: 0.2;
}

.RadioField_hiddenInput:checked + .RadioField_checkbox::before {
  opacity: 1;
}

.RadioField_checkbox::after {
  display: block;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  width: 6px;
  height: 6px;
  background: #0a2540;
  transform: scale(0.5);
  opacity: 0;
  transition: 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-property: opacity, transform;
  border-radius: inherit;
  content: "";
}

.RadioField_hiddenInput:checked + .RadioField_checkbox::after {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 1112px) {
  .ProductHeroSection.Section {
    --sectionContentAngledMarginBottom: 80px;
  }
}
@media (min-width: 600px) {
  .ProductNavItem {
    padding: 0 8px;
  }
  .formLabelContainer {
    flex-basis: 160px;
    margin: 4px 16px 0 0;
  }
}
@media (max-width: 599px) {
  .formItem {
    flex-direction: column;
  }
}